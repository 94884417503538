<template>
  <div class="header-wrapper">
    <header>
      <div class="container-sm topbar d-flex align-items-center nav-t">
        <div @click="humbargerButtonHandler" class="mobile-toggle-menu d-block d-lg-none text-white" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"><i class='bx bx-menu'></i>
        </div>

        <div class="app-log">
          <router-link to="/" class="log-nav">Form256</router-link>
        </div>
        <nav class="navbar navbar-expand gap-3">
          <div class="topbar-logo-header d-flex d-lg-flex">
            <div></div>
          </div>

          <div class="top-menu ms-auto"></div>
          <div class="nav-item user-box dropdown ps-3" ref="dropdown">
            <a role="button" class="d-flex align-items-center" @click="toggleDropdown">
              <div class="user-info pe-2">
                <p class="user-name mb-0">
                  {{ formatString(user.firstname) }}
                  {{ formatString(user.lastname) }}
                </p>
                <p class="designattion mb-0 text-end">
                  Survey {{ formatString(user.role) }}
                </p>
              </div>
              <img src="../../assets/images/user.png" class="user-img" alt="user avatar" />
            </a>
            <ul v-show="enableDropdown" class="dropdown-menu dropdown-menu-end show" data-bs-popper="static">
              <li>
                <a class="disabled dropdown-item d-flex align-items-center" href="javascript:;"><i
                    class="lni lni-user fs-6"></i><span>Profile</span></a>
              </li>
              <li>
                <a class="disabled dropdown-item d-flex align-items-center" href="javascript:;"><i
                    class="lni lni-cog fs-6"></i><span>Settings</span></a>
              </li>
              <li>
                <div class="dropdown-divider mb-0"></div>
              </li>
              <li>
                <a @click="logoutHandler" class="dropdown-item d-flex align-items-center" href="javascript:;"><i
                    class="lni lni-power-switch fs-6"></i><span>Logout</span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>

    <!--navigation-->
    <div class="primary-menu">
      <nav class="navbar navbar-expand-lg align-items-center" style="background: #f7f7ff;">
        <div :class="['offcanvas offcanvas-start', isCollapsed ? '' : 'show']" tabindex="-1" id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header border-bottom">
            <div class="d-flex align-items-center">
              <div class="">
                <h4 class="logo-text">Form256</h4>
              </div>
            </div>
            <button @click="humbargerButtonHandler" type="button" class="btn-close" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav align-items-center gap-1 container">
              <li class="nav-item mobile-search-icon d-flex d-lg-none" data-bs-toggle="modal"
                data-bs-target="#SearchModal"></li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/dashmain' }" class="nav-link"
                  exact-active-class="active-link">Dashboard</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/surveys' }" class="nav-link"
                  exact-active-class="active-link">Surveys</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/reports' }" class="nav-link"
                  exact-active-class="active-link">Reports</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/users' }" class="nav-link"
                  exact-active-class="active-link">Users</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/assets' }" class="nav-link"
                  exact-active-class="active-link">Assets</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/gallery' }" class="nav-link"
                  exact-active-class="active-link">Gallery</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link :to="{ path: '/dashboard/products' }" class="nav-link"
                  exact-active-class="active-link">Products</router-link>
              </li>
              <li class="nav-item" v-if="user?.role === 'admin'">
                <router-link to="#" class="nav-link">
                  Network and Distribution
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isCollapsed" class="offcanvas-backdrop fade show"></div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      enableDropdown: false,
      isCollapsed: true
    };
  },
  watch: {
    '$route.path'() {
      this.isCollapsed = true;
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    ...mapActions(["logout"]),
    async logoutHandler() {
      await this.logout();
      this.$router.push("/login");
    },
    formatString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleDropdown() {
      this.enableDropdown = !this.enableDropdown;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.enableDropdown = false;
      }
    },
    humbargerButtonHandler() {
      this.isCollapsed = !this.isCollapsed
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
a.login {
  cursor: pointer;
}

.topbar-logo-header {
  border-right: 0 !important;
}

.nav-link {
  border-radius: none !important;
  width: unset !important;
  font-size: medium !important;
}

.nav-link:hover,
.nav-link:focus {
  background: unset !important;
  font-size: larger !important;
  border-radius: none !important;
  cursor: pointer;
  color: #14982a;
}

.nav-t {
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.user-info .user-name {
  color: white;
}

.user-info .designattion {
  color: white;
}

.log-nav {
  font-size: 16px !important;
  letter-spacing: 1px !important;
  color: white !important;
  padding-left: 23px;
}

.topbar .navbar .navbar-nav .nav-link {
  border-radius: 3px !important;
}

.app-log {
  border-right: 1px solid white;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 13px;
  color: white !important;
}
</style>
