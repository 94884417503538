import Vue from 'vue'
import Vuex from 'vuex'
import survey from './modules/survey.store'
import login from './modules/login.store'
import users from './modules/users.store'
import assets from './modules/assets.store'
import products from './modules/products.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    survey,
    login,
    users,
    assets,
    products
  }
})
