<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h2 class="h3 mb-0">{{ product ? 'Edit Product' : 'Upload Product' }}</h2>
        <button type="button" class="btn-close" @click="close"></button>
      </div>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Product Photo</label>
              <div class="upload-area">
                <!-- Main Photo -->
                <div class="main-photo mb-2">
                  <div v-if="form.photos.length" class="image-preview">
                    <img :src="decodeURI(form.photos[0].url)" alt="Main product photo" />
                    <button class="btn btn-danger btn-sm remove-btn" @click.prevent="removePhoto(0, $event)">
                      <i class="lni lni-close close"></i>
                    </button>
                  </div>
                  <div v-else class="upload-placeholder" @click="triggerFileUpload">
                    <i class="lni lni-image lg"></i>
                    <span>Upload product photo</span>
                  </div>
                </div>
                <!-- Additional Photos -->
                <div class="additional-photos d-flex">
                  <div v-for="(photo, index) in form.photos.slice(1, 5)" :key="index" class="image-preview me-2">
                    <img :src="decodeURI(photo.url)" :alt="`Additional photo ${index + 1}`" />
                    <button class="btn btn-danger btn-sm remove-btn" @click="removePhoto(index + 1, $event)">
                      <i class="lni lni-close  close"></i>
                    </button>
                  </div>
                  <div v-for="i in (4 - form.photos.slice(1).length)" :key="`empty-${i}`"
                    class="upload-placeholder small me-2" @click="triggerFileUpload">
                    <i class="lni lni-circle-plus"></i>
                  </div>
                </div>
              </div>
              <input type="file" ref="fileInput" accept="image/*" name="photos" multiple @change="handleFileUpload"
                style="display: none" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="productName" class="form-label">Product Name</label>
              <input type="text" id="productName" v-model="form.name" class="form-control" placeholder="Product Name"
                required />
            </div>
            <div class="mb-3">
              <label for="unitPrice" class="form-label">Unit Price (UGX)</label>
              <input type="number" id="unitPrice" v-model="form.unitCost" class="form-control" placeholder="e.g 89,000"
                required />
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea id="description" v-model="form.description" class="form-control" rows="3"
                placeholder="Type anything" required></textarea>
            </div>
          </div>
        </div>
        <div class="text-end mt-4">
          <button type="submit" class="btn btn-primary">{{ product ? 'Update' : 'Next' }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        unitCost: '',
        photos: [], // Array to hold photo URLs and files
      },
    };
  },
  watch: {
    // Populate form data when a product is passed in for editing
    product: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form.name = newVal.name;
          this.form.description = newVal.description;
          this.form.unitCost = newVal.unitCost;
          console.log("newVal.photos", newVal.photos);
          const photosWithUrl = newVal.photos.map(p => ({ url: p }));
          console.log(" photosWithUrl", photosWithUrl);
          this.form.photos = [...photosWithUrl]; // Load product's existing photos
        } else {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    ...mapActions(['uploadImage']),

    close() {
      this.$emit('close');
    },
    resetForm() {
      this.form = {
        name: '',
        description: '',
        unitCost: '',
        photos: [],
      };
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        try {
          const formData = new FormData();

          // Generate a unique file name using "product" + random number or timestamp
          const randomNumber = Math.floor(Math.random() * 1000); // Generates a random number between 0-999
          const fileExtension = file.name.split('.').pop(); // Get the file extension
          const newFileName = `product_${randomNumber}.${fileExtension}`; // e.g. product_123.jpg

          // Create a new file object with the new name
          const renamedFile = new File([file], newFileName, { type: file.type });

          // Append renamed file to formData
          formData.append('photos', renamedFile);

          // Upload the image
          const response = await this.uploadImage({ formData });
          console.log("response", response.uploadedFiles[0]);

          // Assuming the response contains the URL of the uploaded image
          const photoURL = response.uploadedFiles[0];

          this.form.photos.push({
            url: photoURL, // Store the URL returned from the server
          });

        } catch (error) {
          console.error('Error uploading image:', error);
          // Handle error (e.g., show an error message to the user)
        }
      }

      event.target.value = null; // Clear input to allow same file selection
    },
    removePhoto(index, event) {
      event.stopPropagation();
      console.log("index", index);
      this.form.photos.splice(index, 1); // Remove selected photo
    },
    submitForm() {
  if (this.form.name.trim() && this.form.unitCost) {
    // Proceed with form submission only if necessary fields are filled
    // Other conditions to prevent auto-submit when only photos are changed
    const user = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();

    formData.append('name', this.form.name);
    formData.append('description', this.form.description);
    formData.append('price', this.form.unitCost);
    formData.append('category', "vegetables"); // Assuming static category
    formData.append('userId', user.id);

    // Handling photos
    this.form.photos.forEach(photo => {
      if (photo.file) {
        formData.append('photos', photo.file); 
      }
    });

    const payload = {
      name: this.form.name,
      description: this.form.description,
      price: this.form.unitCost,
      category: "vegetables",
      userId: user.id,
      photos: this.form.photos.filter(photo => photo.url && !photo.file).map(photo => photo.url)
    };

    if (this.product) {
      this.$emit('update-product', { formData: payload, id: this.product.id });
    } else {
      this.$emit('create-product', { formData: payload });
    }

    this.close();
  } else {
    // Handle validation or prompt user to fill in required fields
  }
}





  },
};

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 1rem;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-color: #f8f9fa;
  border-radius: 4px;
  cursor: pointer;
}

.upload-placeholder.small {
  width: 60px;
  height: 60px;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.additional-photos .image-preview {
  width: 60px;
  height: 60px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lg {
  font-size: 50px;
}

.close {
  margin-right: 1px;
  font-size: 12px;
}
</style>
