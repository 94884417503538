import Vue from 'vue'
import VueRouter from 'vue-router'
import SurveyFormView from '@/views/SurveyFormView.vue'
import SurveyTableView from '@/views/SurveyTableView.vue'
import LoginView from '@/views/LoginView.vue'
import LayoutView from '@/views/LayoutView.vue'
import DashboardTable from '@/components/dashboard/DashboardTable.vue'
import DashboardComponent from '@/components/dashboard/Dashboard.vue'
import AnsweredSurveys from '@/components/dashboard/AnsweredSurveys.vue'
import ListUsers from '@/components/dashboard/ListUsers.vue'
import SingleSurveyDetails from '@/components/dashboard/SingleSurveyDetails.vue'
import SingleAnswerDetails from '@/components/dashboard/SingleAnswerDetails.vue'
import Reports from '@/components/dashboard/Reports.vue'
import ListOfAssets from '@/components/dashboard/ListOfAssets.vue'
import GalleryView from '@/components/dashboard/GalleryView.vue'
import ProductsTable from '@/components/dashboard/ProductsTable.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutView,
    children: [
      {
        path: '/',
        name: 'survey-table',
        component: SurveyTableView,
        meta: { role: 'agent' }
      },
      {
        path: '/survey/:id',
        name: 'survey-form',
        component: SurveyFormView,
        meta: { role: 'agent' }
      },
      {
        path: '/dashboard',
        component: DashboardComponent,
        meta: { role: 'admin' },
        children: [
          {
            path: 'dashmain',
            name: 'dashboard-table',
            component: DashboardTable,
            meta: { role: 'admin' },
          },
          {
            path: 'surveys',
            component: AnsweredSurveys,
            meta: { role: 'admin' },
          },
          {
            path: '/single-survey/:id',
            name: 'survey-forms',
            component: SingleSurveyDetails,
            meta: { role: 'admin' },
          },
          {
            path: '/answer/:id',
            name: 'single-answer',
            component: SingleAnswerDetails,
            meta: { role: 'admin' },
          },
          {
            path: 'users',
            component: ListUsers,
            meta: { role: 'admin' },
          },
          {
            path: 'reports',
            component: Reports,
            meta: { role: 'admin' },
          },
          {
            path: 'assets',
            component: ListOfAssets,
            meta: { role: 'admin' },
          },
          {
            path: 'gallery',
            component: GalleryView,
            meta: { role: 'admin' },
          },
          {
            path: 'products',
            name: 'products-table',
            component: ProductsTable,
            meta: { role: 'admin' },
          }          
        ]
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { guestOnly: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))

  if (to.name === "survey-table" && from.name === "login" && !isAuthenticated) {
    return
  }
  else if (to.name === "dashboard-table" && from.name === "login" && !isAuthenticated) {
    return
  }
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' })
  }
  else if (to.meta?.role === "agent" && user.role === "admin" && isAuthenticated) {
    next({ name: 'dashboard-table' })
  }
  else if (to.meta?.role === "admin" && user.role === "agent" && isAuthenticated) {
    next({ name: 'survey-table' })
  }
  else if (to.matched.some(record => record.meta.guestOnly) && isAuthenticated) {
    next({ name: 'survey-table' })
  }
  else {
    next()
  }
})

export default router
