<template>
    <div class="container-sm">
        <BreadCrumb main="Gallery" sub="List"></BreadCrumb>

        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4" v-for="image in paginatedImages" :key="image">
                            <img
                                :src="require(`../../assets/images/gallery/${image}`)"
                                class="img-fluid"
                                alt="Gallery image"
                                style="max-height: 400px; margin-bottom: 10px;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PaginationComponent
          v-if="images.length > 0"
          :page="page"
          :total="images.length"
          :pageSize="pageSize"
          @changePage="changePage"
          class="align-self-center"
        />
    </div>
</template>

<script>
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import PaginationComponent from "../common/PaginationComponent.vue";
import paginationMixin from "@/mixins/paginationMixin";

export default {
    name: 'GalleryView',
    mixins: [paginationMixin],
    components: {
        BreadCrumb,
        PaginationComponent
    },
    data() {
        return {
            images: [],
            page: 1,
            pageSize: 6
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.images.length / this.pageSize);
        },
        paginatedImages() {
            const start = (this.page - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.images.slice(start, end);
        }
    },
    mounted() {
        for (let i = 1; i <= 169; i++) {
            this.images.push(`${i}.jpg`);
        }
  }
}
</script>