import instance from "../../config";

const state = {
  loadingProduct: false,
  products: [],
  allProducts: [],
  total: 0,
};

const mutations = {
  SET_LOADING(state, isLoading) {
    state.loadingProduct = isLoading;
  },

  SET_PRODUCTS(state, products) {
    state.products = products;
  },

  SET_ALL_PRODUCTS(state, allProducts) {
    state.allProducts = allProducts;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  }
};

const getters = {
  getProducts: (state) => state.products,
  isLoadingProducts: (state) => state.loadingProduct,
  getAllProducts: (state) => state.allProducts,
  getTotal: (state) => state.total,


};

const actions = {
  async fetchProducts({ commit }, payload) {
    commit("SET_LOADING", true);
    await instance
      .get(`/products?page=${payload.page}&pageSize=${payload.pageSize}`)
      .then((response) => {
        console.log("products", response.data.data.products);
        console.log("total", response.data.data.total);
        commit("SET_PRODUCTS", response.data.data.products);
        commit("SET_TOTAL", response.data.data.total);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },

  async fetchAllProducts({ commit }) {
    commit("SET_LOADING", true);
    await instance
      .get('/products/all') // API endpoint to fetch all products
      .then((response) => {
        commit("SET_ALL_PRODUCTS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },

  // Create a new product
  // eslint-disable-next-line
  async createProduct({ commit }, { formData }) {
    console.log("payloadCreate", formData);

    if (formData.photos && Array.isArray(formData.photos)) {
      formData.photos = formData.photos.map(photo => encodeURI(photo));
    }

    try {
      const response = await instance.post(`/products`, formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  // Update an existing product
  // eslint-disable-next-line
  async updateProduct({ commit }, { formData, id }) {
    console.log("id", id, formData);
  
    // Check if the photo URLs are already encoded, and only encode if necessary
    console.log("formData.photosBeforeEncoding", formData.photos);
    if (formData.photos && Array.isArray(formData.photos)) {
      formData.photos = formData.photos.map(photo => {
        // If the URI contains encoded characters (like %20 for space), skip encoding
        console.log("photo.includes('%')", photo.includes('%'));
        if (photo.includes('%')) {
          return photo; // It's already encoded, so return as-is
        } else {
          return encodeURI(photo); // Otherwise, encode the URI 
        }
      });
    }
    
    console.log("formData.photosAfterEncoding", formData.photos);
  
    return await instance
      .put(`/products/${id}`, formData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  


  // Delete a product
  // eslint-disable-next-line
  async deleteProduct({ commit }, id) {
    return await instance
      .delete(`/products/${id}`) // Assuming this is the correct endpoint
      .then((response) => {
        return response.data; // Returning a confirmation or deleted product's data
      })
      .catch((error) => {
        console.error(error);
        throw error; // Re-throw the error for handling in the component
      });
  },

  // In your actions section
  // eslint-disable-next-line
  async toggleProductPublish({ commit }, id) {
    console.log("idInPublish", id);
    try {
      const response = await instance.patch(`/products/${id}/toggle-publish`);
      console.log("Toggle Publish Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error toggling publish state:", error);
      throw error;
    }
  },
  // eslint-disable-next-line
  async uploadImage({ commit }, { formData }) {
    console.log("formData", formData);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    return await instance
      .post(`/products/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

};

export default {
  state,
  mutations,
  getters,
  actions,
};
