<template>
  <div id="mapChart" height="100"></div>
</template>

<script>
import L from 'leaflet';

export default {
  name: "MapChart",
  props: {
    pointers: {
      required: false
    }
  },
  data() {
    return {
      minResponse: 0,
      maxResponse: 300,
    }
  },
  async mounted() {
    await this.renderChart();
  },
  methods: {
    renderChart() {
      var map = L.map('mapChart').setView([1.3733, 32.2903], 7);

      // Add tile layer for map background
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      const defaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      
      this.pointers.forEach(pointer => {
        let label = '';
        if (pointer.Answer[0]?.answerText) {
          label = `Survey: ${pointer.survey.alias}, Company: ${pointer.Answer[0]?.answerText}`
        } else {
          label = `Survey: ${pointer.survey.alias}`
        }
        
        L.marker([pointer.latitudes, pointer.longitudes], { icon: defaultIcon }).addTo(map)
          .bindPopup(label)
          .openPopup();
      });
    },
  },
};
</script>

<style scoped>
.chart-container-2 {
  position: relative;
}

#mapChart {
  height: 500px;
}
</style>
