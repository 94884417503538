<template>
  <div class="container-sm">
    <BreadCrumb
      main="Products"
      sub="List"
    >
      <button type="button" @click="openCreateForm"  class="btn-md btn btn-primary">
        <i class="lni lni-circle-plus"></i> Add New Product</button>
    </BreadCrumb>

    <CreateProductForm
      v-if="showCreateForm"
      @close="closeCreateForm"
      @create-product="handleCreateProduct"
      @update-product="handleUpdateProduct"
      :product="currentProduct"
    />

    <div v-if="isLoadingProducts" class="loader-container">
      <LoaderComponent />
    </div>

    <div v-else>
      <div class="mb-4 position-relative">
        <i class="lni lni-magnifier search-icon"></i>
        <input 
          type="text" 
          class="form-control pl-5 search-input" 
          placeholder="Search" 
          v-model="searchQuery"
        >
      </div>


      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th @click="sortBy('name')">Product Name <i class="bi bi-arrow-down-up"></i></th>
                  <th @click="sortBy('description')">Description <i class="bi bi-arrow-down-up"></i></th>
                  <th @click="sortBy('photos')">Photos <i class="bi bi-arrow-down-up"></i></th>
                  <th @click="sortBy('unitCost')">Unit Cost <i class="bi bi-arrow-down-up"></i></th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in sortedAndFilteredProducts" :key="product.id">
                  <td>{{ product.name }}</td>
                  <td>{{ product.description }}</td>
                  <td>
                    <div class="d-flex">
                      <img v-for="(photo, index) in product.photos.slice(0, 4)" :key="index" :src="decodeURI(photo)" class="product-thumbnail me-1" :alt="product.name">
                    </div>
                  </td>
                  <td>{{ formatCurrency(product.unitCost) }}</td>
                  <td>
                    <button 
                        class="btn btn-sm me-2" 
                        :class="product.isPublished ? 'btn-outline-warning' : 'btn-outline-success'" 
                        @click="togglePublish(product)"
                      >
                        {{ product.isPublished ? 'Unpublish' : 'Publish' }}
                      </button>
                    <button class="btn btn-sm btn-outline-primary me-2" @click="editProduct(product)">Edit</button>
                    <button class="btn btn-sm btn-outline-danger" @click="confirmDeleteProduct(product)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <div>
          <button class="btn btn-sm btn-outline-secondary me-2" @click="prevPage" :disabled="currentPage === 1">&lt;</button>
          <button class="btn btn-sm btn-outline-secondary" @click="nextPage" :disabled="currentPage >= totalPages">&gt;</button>
        </div>
        <div>{{ currentPage }}-{{ totalPages }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import CreateProductForm from "./CreateProductForm.vue";


export default {
  name: "ProductsTableView",
  components: {
    BreadCrumb,
    LoaderComponent,
    CreateProductForm,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      currentProduct: null,
      showCreateForm: false,
      searchQuery: '',
      sortKey: 'name',
      sortOrder: 1,
    };
  },
  computed: {
    ...mapGetters(['getProducts', 'isLoadingProducts', 'getTotal']),
    
    sortedAndFilteredProducts() {
      let filtered = this.getProducts.filter(product => 
        product.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      filtered.sort((a, b) => {
        let modifier = this.sortOrder;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      const decodedProducts = this.getProducts.map(p => p.photos.map(p => decodeURI(p)));
      console.log("decodedProducts", decodedProducts);

      let start = (this.currentPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      return filtered.slice(start, end);
    },

    
    totalPages() {
      return Math.ceil((this.getTotal || this.getProducts.length) / this.pageSize);
    },
  },
  methods: {
    ...mapActions(['fetchProducts', 'deleteProduct', 'toggleProductPublish']),
    
    async fetchProductList() {
      await this.fetchProducts({ page: this.currentPage, pageSize: this.pageSize });
    },
    
    editProduct(product) {
      console.log("EditProduct", product);
      this.currentProduct = product;
      this.showCreateForm = true;
    },
    
    async confirmDeleteProduct(product) {
      if (confirm('Are you sure you want to delete this product?')) {
        await this.deleteSelectedProduct(product);
      }
    },
    
    async deleteSelectedProduct(product) {
      try {
        await this.deleteProduct(product.id);
        this.fetchProductList();
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    },
    
    openCreateForm() {
      this.currentProduct = null;
      this.showCreateForm = true;
    },
    
    closeCreateForm() {
      this.showCreateForm = false;
      this.fetchProductList();
    },
    
    formatCurrency(value) {
      return new Intl.NumberFormat('en-UG', { style: 'currency', currency: 'UGX' }).format(value);
    },
    
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    
    async handleCreateProduct(productData) {
      try {
        // Call the Vuex action to create a new product
        await this.$store.dispatch('createProduct', productData);

        // Optionally, you can add any additional logic after product creation,
        // like showing a success message, or refetching products
        await this.fetchProductList(); // Refresh the product list
      } catch (error) {
        console.error('Error creating product:', error);
        // Optionally handle the error (e.g., show a notification)
      }
    },
    async handleUpdateProduct(productData) {
      try {
        // Call the Vuex action to create a new product
        await this.$store.dispatch('updateProduct', productData);

        // Optionally, you can add any additional logic after product creation,
        // like showing a success message, or refetching products
        await this.fetchProductList(); // Refresh the product list
      } catch (error) {
        console.error('Error updating product:', error);
        // Optionally handle the error (e.g., show a notification)
      }
    },
    async togglePublish(product) {
    try {
        console.log("Toggling publish for product:", product);
        const updatedProduct = await this.toggleProductPublish(product.id);
        console.log("Updated product state:", updatedProduct);
        this.fetchProductList(); // Refresh the product list
    } catch (error) {
        console.error("Error toggling product publish state:", error);
    }
}

  },
  
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        this.fetchProductList();
      },
    },
  },
  
  mounted() {
    this.fetchProductList();
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.product-thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.table th {
  cursor: pointer;
}

.table th i {
  font-size: 0.8em;
  margin-left: 5px;
}

.search-input {
  width: 600px; /* Set a fixed width */
  max-width: 100%; /* Ensure it doesn't exceed container width */
}


.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.form-control.pl-5 {
  padding-left: 2.5rem;
}
</style>
