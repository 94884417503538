<template>
  <div class="wrapper container px-0">
    <BreadCrumb main="Dashboard" sub="stats" class="ps-2" />
    <div class="page-wrapper mt-0">
      <div class="page-content px-0 pt-3">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-4 border-success"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Number of Agents</p>
                    <h4 class="my-1 text-success">{{ stats?.agents }}</h4>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"
                  >
                    <i class="lni lni-users"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-4 border-info"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Number of Responses</p>
                    <h4 class="my-1 text-info">{{ stats?.responses }}</h4>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-blues text-white ms-auto"
                  >
                    <i class="lni lni-help"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-4 border-danger"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Number of Surveys</p>
                    <h4 class="my-1 text-danger">{{ stats?.surveys }}</h4>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-burning text-white ms-auto"
                  >
                    <i class="lni lni-library"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-4 border-warning"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Responses per Agent</p>
                    <h4 class="my-1 text-warning">{{ stats?.responsePerAgents.toFixed(2) }}</h4>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-orange text-white ms-auto"
                  >
                    <i class="lni lni-archive"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-12 col-lg-8 d-flex">
            <div class="card radius-10 w-100">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <div>
                    <h6 class="mb-0">Total Survey Responses</h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div>
                    <Chart v-if="stats" :graphData="JSON.parse(stats?.graphData)"/>
                </div>
               
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="card radius-10 w-100">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <div>
                    <h6 class="mb-0">Number of Responses per Region.</h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="">
                  <PieChart v-if="stats" :pieChart="JSON.parse(stats?.pieChart)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
        <div class="row">
          <div class="col-12 d-flex">
            <div class="card radius-10 w-100">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <div>
                    <h6 class="mb-0">Total Survey Responses Per District</h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div>
                     <MapChart v-if="stats" :pointers="stats?.pointers" />
                </div>
               
              </div>
            </div>
          </div>
          
        
        </div>
        <!--end row-->
        
      </div>
    </div>
    <!--end page wrapper -->
  </div>
</template>

<script>
import Chart from './Chart.vue'
import PieChart from './PieChart.vue'
import MapChart from './Map.vue'
import { mapActions } from "vuex";
import BreadCrumb from "@/components/common/BreadCrumb.vue";

export default {
    name: "DashboardTable",
    data() {
      return {
        stats: null
      }
    },
    components: {
        Chart,
        PieChart,
        MapChart,
        BreadCrumb
    },
    async mounted() {
      await this.fetchDashboardStats()
      this.stats = this.$store.state.survey.dashboardStats
    },
    methods: {
      ...mapActions(["fetchDashboardStats"]),
    }
};
</script>

<style scoped>
.subHeader {
  width: '80vw';
}


</style>
